@import '../../styles/variables';
@import '../../styles/mixins';

.ck-content {
  height: 400px;
  color: $text-color;
  font-size: 14px;
  font-family: $font-family-default;
  line-height: 1.25;

  &.ck-focused,
  &:focus {
    box-shadow: $blue-color 0 0 0 2px !important;
  }

  @include inside('div.error') {
    border-color: $red-color !important;

    &.ck-focused,
    &:focus {
      box-shadow: $red-color 0 0 0 2px !important;
    }
  }

  h1, h2, h3 {
    margin: 1em 0;
    font-weight: bold;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }

  p {
    line-height: 1.25;
  }

  a {
    color: var(--ctaColor, $blue-color);
    text-decoration: none;

    @include hoverTransition(filter) {
      filter: brightness(.5);
    }
  }

  ul {
    list-style-type: initial;
  }

  ol {
    list-style-type: decimal;
  }

  ul, ol {
    margin: 1em 0;
    padding-left: 1.5rem;
    list-style-position: outside;

    li {
      margin-top: 5px;
      line-height: 1.25;
    }
  }

  strong, b {
    font-weight: bold;
  }

  em, i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }
}
