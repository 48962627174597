.Label {
  svg path {
    fill: #8e8ea9;
  }
}

.Toggle {
  > div > div > label > div > div {
    span {
      font-size: 0;

      &::after {
        font-size: .75rem;
      }
    }

    &:first-child {
      span::after {
        content: 'Nein';
      }
    }

    &:nth-child(2) {
      span::after {
        content: 'Ja';
      }
    }
  }
}
