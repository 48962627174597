@use 'sass:math';
@import '../../styles/variables';
@import '../../styles/functions';

.Select {
  font-size: .875rem;

  :global .select {
    &__control {
      &--is-focused {
        border-color: $blue-color !important;
        box-shadow: $blue-color 0 0 0 2px;
      }
    }

    &__option {
      &--is-selected {
        background-color: $blue-color;
      }
    }

    &__multi-value {
      &__label {
        font-size: 100%;
      }
    }
  }

  &.error {
    :global .select {
      &__control {
        border-color: $red-color;

        &--is-focused {
          border-color: $red-color !important;
          box-shadow: $red-color 0 0 0 2px;
        }
      }
    }
  }
}

.IconBox {
  svg {
    width: math.div(11, 16) * 1rem;
    height: math.div(11, 16) * 1rem;
  }
}

.CarretBox {
  svg {
    width: math.div(6, 16) * 1rem;
  }
}

.Label {
  box-sizing: border-box;
  max-width: 100%;
  padding: rem(4px);
  border-width: thin;
  border-style: solid;
  border-radius: 2px;
  color: $dim-gray-color;
  font-size: em(12px, 16px);
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.white {
    border: thin solid $dim-gray-color;
  }

  @each $name, $color in $label-colors {
    &.#{$name} {
      $background-color: tint($color, 75%);
      border-color: $background-color;
      color: $color;
      background-color: $background-color;
    }
  }
}
