.Layout {
  box-sizing: border-box;
  display: grid;
  grid: 'header' auto 'main' 1fr / 1fr;
  min-height: 100vh;

  &Header {
    grid-area: header;

    > div {
      padding-left: 0;
      padding-right: 0;

      > div {
        align-items: flex-start;
      }
    }
  }

  &Container {
    grid-area: main;
  }

  &Content {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
