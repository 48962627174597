// screens
$screen-sm: 320px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1480px;

// transitions
$transition-slow: .35s;
$transition-normal: .25s;
$transition-medium: .15s;
$transition-fast: .1s;

$transition-hover-on: $transition-fast;
$transition-hover-off: $transition-normal;

// fonts
$font-family-default: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

// colors
$text-color: black;
$border-color: rgba(0, 0, 0, .16);
$blue-color: #0a6aaf;
$astronaut-blue-color: #144154;
$orient-color: #1e617d;
$dim-gray-color: #707070;
$contrast-gray-color: #585858;
$iron-color: #c3c5c5;
$yellow-green-color: #8ad613;
$green-color: #007c00;
$red-color: #d02b20;
$blue-color: #4945ff;
$bg-color: #f6f6f9;

$label-colors: (
  "yellow": #e6af14,
  "cyan": #17a2b8,
  "red": #c84182,
  "blue": #3c69be
);

// container
$container-width: 1280px;
$container-padding: 20px;

// shadows
$box-shadow: 0 0 6px rgba(black, .16);
$text-shadow: 0 0 6px rgba(black, .16);

// env
$env-top: env(safe-area-inset-top, 0);
$env-bottom: env(safe-area-inset-bottom, 0);
$env-left: env(safe-area-inset-left, 0);
$env-right: env(safe-area-inset-right, 0);
$env-top-bottom: '(#{$env-top} + #{$env-bottom})';
