@import '../../styles/variables';
@import '../../styles/mixins';

.progress {
  box-sizing: border-box;
  width: 100%;
  padding: 1em;

  &Outer {
    box-sizing: border-box;
    height: 8px;
    border-radius: 5px;
    background-color: #666687;
    overflow: hidden;
  }

  &Inner {
    box-sizing: border-box;
    height: 100%;
    background-color: var(--ctaColor, #eaeaef);
    border-radius: 5px;
    @include transition(width, ease-out, 500ms);
  }
}
