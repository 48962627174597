@import '../../styles/variables';
@import '../../styles/mixins';

.Preloader {
  @include full;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(white, .75);
  z-index: 9999;
}
