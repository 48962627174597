@use 'sass:math';
@use 'sass:list';
@import 'variables';

@mixin click-area($px: 48px) {
  position: relative;
  &::after {
    content: '';
    display: block;
    min-width: $px;
    width: 100%;
    min-height: $px;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin aspect-ratio-before($width: 1, $height: 1) {
  position: relative;
  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }
}

@mixin aspect-ratio($width: 1, $height: 1) {
  position: relative;
  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }
  > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
  }
}

@mixin w-from($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

@mixin w-to($screen) {
  @media (max-width: $screen - 1px) {
    @content;
  }
}

@mixin w-range($screen1, $screen2) {
  @media (min-width: $screen1) and (max-width: $screen2 - 1px) {
    @content;
  }
}

@mixin hover() {
  @media screen and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}


@mixin focus() {
  @media screen and (pointer: fine) {
    &:focus {
      @content;
    }
  }
}

@mixin inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin qualify($element-selector) {
  @at-root #{$element-selector + &} {
    @content;
  }
}

@mixin hideScrollbar() {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin scrollbar(
  $size: 5px,
  $foreground-color: #aaaaaa,
  $background-color: mix($foreground-color, white, 50%)
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }
}

@mixin transition(
  $property: all,
  $timing-function: ease,
  $duration: $transition-normal,
  $delay: null
) {
  transition-property: #{$property};
  transition-timing-function: $timing-function;
  transition-duration: $duration;
  @if $delay {
    transition-delay: $delay;
  }
}

@mixin hoverTransition(
  $property: all,
  $timing-function: ease,
  $duration: $transition-hover-off,
  $duration-on: $transition-hover-on
) {
  @include transition($property, $timing-function, $duration);

  &:focus {
    @content;
    transition-duration: $duration-on;
  }

  @include hover {
    @content;
    transition-duration: $duration-on;
  }
}

@mixin position($position: absolute, $params: (0, auto, auto, 0)) {
  position: $position;
  top: list.nth($params, 1);
  right: list.nth($params, 2);
  bottom: list.nth($params, 3);
  left: list.nth($params, 4);
}

@mixin position-2($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin full($position: absolute) {
  @include position($position, 0 0 0 0);
  width: 100%;
  height: 100%;
}

@mixin absolute($args...) {
  @include position-2(absolute, $args...);
}

@mixin relative($args...) {
  @include position-2(relative, $args...);
}

@mixin fixed($args...) {
  @include position-2(fixed, $args...);
}

@mixin prefix($declarations, $prefixes: ()) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }

    #{$property}: $value;
  }
}

@mixin stripes($direction, $colors) {
  $length: length($colors);

  @if $length > 1 {
    $stripes: ();

    @for $i from 1 through $length {
      $stripe: (100% / $length) * ($i - 1);

      @if $i > 1 {
        $stripes: append($stripes, nth($colors, $i - 1) $stripe, comma);
      }

      $stripes: append($stripes, nth($colors, $i) $stripe, comma);
    }

    background-image: linear-gradient($direction, $stripes);
  } @else if $length == 1 {
    background-color: $colors;
  }
}

@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}
