@import 'variables';

.Container {
  box-sizing: border-box;
  width: 100%;
  min-width: 100%;
  max-width: $container-width + 2 * $container-padding;
  margin: 0 auto;
  padding: 0 $container-padding;
}
