.AddAsset {
  width: 100%;
  height: 120px;
}

.Image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
