@import '../../styles/mixins';

.SelectAsset {
  width: 90vw !important;
}

.Content {
  min-height: 400px;
}

.UploadZone {
  position: relative;
  height: 350px;

  > div {
    height: 100%;
  }

  input[type="file"] {
    @include full;
    opacity: 0;
    cursor: pointer;
  }

  button {
    pointer-events: none;
  }
}
